import React, { useEffect, useState, useMemo } from 'react';
import './home.css'; // Importe seu arquivo CSS aqui
import PhotoService from '../../services/photoServices';
import VideoGlobal from '../../components/VideoGlobal';
const seedrandom = require('seedrandom');

// let lst = [
// 	'01.jpg', '02.jpg', '03.jpg', '04.jpg', '05.jpg', '06.jpg', '07.jpg', '08.jpg', '09.jpg', '10.jpg',
// 	'11.jpg', '12.jpg', '13.jpg', '14.jpg', '15.jpg', '16.jpg', '17.jpg', '18.jpg', '19.jpg', '20.jpg',
// 	'21.jpg', '22.jpg', '23.jpg', '24.jpg', '25.jpg', '26.jpg', '27.jpg', '28.jpg', '29.jpg', '30.jpg',
// 	'31.jpg', '32.jpg', '33.jpg', '34.jpg', '35.jpg', '36.jpg', '37.jpg', '38.jpg', '39.jpg', '40.jpg',
// 	'41.jpg', '42.jpg', '43.jpg', '44.jpg', '45.jpg', '46.jpg', '47.jpg', '48.jpg', '49.jpg', '50.jpg',
// 	'51.jpg', '52.jpg', '53.jpg', '54.jpg', '55.jpg', '56.jpg', '57.jpg', '58.jpg', '59.jpg', '60.jpg',
// 	'61.jpg', '62.jpg', '63.jpg', '64.jpg', '65.jpg', '66.jpg', '67.jpg', '68.jpg', '69.jpg', '70.jpg',
// 	'71.jpg', '72.jpg', '73.jpg', '74.jpg', '75.jpg', '76.jpg', '77.jpg', '78.jpg', '79.jpg'
// ];

function App(props) {
	const [photos, setPhotos] = useState([]);
	const totalSpaces = 244;
	const [currentPhoto, setCurrentPhoto] = useState("img/fundo1.svg");
	const [classPhoto, setClassPhoto] = useState("fundo1");
	const [opacity, setOpacity] = useState(window.BaseUrl.OPACITY);
	const [showDiv1, setShowDiv1] = useState(true);

	useEffect(() => {
		const getPhotos = async () => {
			try {
				if (showDiv1) {
					let resp2 = await PhotoService.GetPhotos();
					let data = [{ preenchido: false }];
					let photoList = resp2.LstClasse;

					if (resp2.Success) {
						while (photoList.length < totalSpaces) {
							photoList = [...photoList, ...data];
						}

						photoList = shuffleArrayWithSeed([...photoList], "2f31dca2aefa3a05b96ddbd8aea7eca6");

						setPhotos([
							...photoList,
							...photoList,
						]);
					}
				}
			} catch (ex) {

			}
		}

		// const getPhotos = () => {
		// 	let photoList = lst;
		// 	let data = [{ preenchido: false }];
		// 	while (photoList.length < totalSpaces) {
		// 		photoList = [...photoList, ...data];
		// 	}
		// 	photoList = shuffleArrayWithSeed([...photoList], "2f31dca2aefa3a05b96ddbd8aea7eca6");

		// 	setPhotos([
		// 		...photoList,
		// 		// ...photoList
		// 	]);
		// }
		// getPhotos();

		getPhotos();
		setInterval(() => {
			getPhotos();
		}, 5000);
	}, []);

	function shuffleArrayWithSeed(array, seed) {
		// Criar um gerador de números aleatórios com a semente fornecida
		const rng = seedrandom(seed);

		// Algoritmo de Fisher-Yates para embaralhar o array
		for (let i = array.length - 1; i > 0; i--) {
			const j = Math.floor(rng() * (i + 1));
			[array[i], array[j]] = [array[j], array[i]];
		}

		return array;
	}

	const getListPhotos2 = useMemo(() => {
		let url;
		if (process.env.NODE_ENV !== 'development') {
			url = window.BaseUrl.PROD_ENDPOINT_PHOTO;
		} else {
			url = window.BaseUrl.DEV_ENDPOINT_PHOTO;
		}

		// console.log(photos);

		return (
			photos.map((photo, index) => {
				if (photo?.preenchido === false) {
					return (
						<div key={index} className="photo-item" alt={index}>
							<div className={`fundoBranco`}></div>
						</div>
					)
				} else {
					return (
						<div key={index} alt={index} className={`photo-item visivel`}>
							{/* <div className={`fundoBranco`}></div> */}
							<img src={`${url}/${photo.NomeFoto}`} />
						</div>
					)
				}
			})
		)
	}, [photos]);

	const getStyle = useMemo(() => {
		return photos.length > 0 ? { opacity: opacity, transition: 'opacity 2s ease' } : { opacity: window.BaseUrl.OPACITY };
	}, [photos]);

	useEffect(() => {
		const timer = setInterval(() => setShowDiv1(prev => !prev), 11 * 60 * 1000); // 10 minutos
		return () => {
			clearInterval(timer);
		};
	}, []);

	return (
		<>
			{showDiv1 === true && photos?.length > 0 && (
				<div className={`containerGlobal ${classPhoto}`}>
					<div className="photo-grid-1">
						{getListPhotos2}

						<div className="photo-item oswaldo">
							<img src={'img/oswaldo.jpg'}></img>
						</div>

						<div className="logoGlobal" style={getStyle}>
							<img
								src={currentPhoto}
							></img>
						</div>
					</div>
				</div>
			)}

			{showDiv1 === false && (
				<VideoGlobal
					style={true}
					path="videos/principal/"
					urls={[
						"P_01_fotos antigas_oswaldo_e_rodrigo.mp4",
						"P_02_kickoff_keyrus_2011.mp4",
						"P_03_dayoff_2011.mp4",
						"P_04_15_anos_global_2014.mp4",
						"P_05_confra_2013.mp4",
						"P_06_confra_2014_pt_1.mp4",
						"P_07_confra_2014_pt_2.mp4",
						"P_08_confra_2018_pt_1.mp4",
						"P_10_confra_2018_pt_2.mp4",
						"P_11_confra_2018_pt_3.mp4",
						"P_12_confra_2018_pt_4.mp4",
						"P_13_confra_2018_pt_5.mp4",
						"P_14_confra_2018_pt_1.mp4",
						"P_15_confra_2018_pt_2.mp4",
						"P_16_confra_2018_pt_3.mp4",
						"P_17_confra_2019_pt_4.mp4",
						"P_18_confra_2019_pt_5.mp4",
						"P_19_confra_2022.mp4",
						"P_20_hallowen.mp4",
						"P_21_outubro_rosa.mp4",
						"P_22_novembro_azul.mp4",
						"P_23_dia_internacional_da_mulher.mp4",
						"P_24_dia_a_dia.mp4",
						"P_25_times_externos_pt_1.mp4",
						"P_26_times_externos_pt_2.mp4",
						"P_27_times_externos_pt_3.mp4",
						"P_28_pt_na_areia_pt_1.mp4",
						"P_28_pt_na_areia_pt_1.mp4",
						"P_29_pt_na_areia_pt_2.mp4",
						"P_30_pt_na_areia_pt_3_.mp4",
						"P_31_amigo_secreto_2023.mp4",
						"P_32_confra_2023_pt_1.mp4",
						"P_33_confra_2023_pt_2.mp4",
						"P_34_confra_2023_pt_3.mp4",
						"P_35_confra_2023_pt_4.mp4",
						"P_36_projetos.mp4",
						"P_37_meet.mp4",
						"P_38_INTRO_BusiNess_Solutions_purpura.mp4",
						"P_38_INTRO_BusiNess_Solutions_purpura_.mp4",
						"P_40_INTRO_Global_pixels_Purpura.mp4",
						"P_41_INTRO_global_glitch_purpura.mp4",
						"P_43_INTRO_05_Logo_One.mp4",
						"P_44_INTRO_global_pixels_.mp4",
						"P_45_novo.mp4",
						"P_46_novo.mp4",
						"P_47_novo.mp4",
						"P_48_novo.mp4",
						"P_49_novo.mp4",
						"P_50_novo.mp4",
						"P_51_novo.mp4",
						"P_52_novo.mp4",
					]}
				/>
			)}
		</>
	);
}

export default App;