import ReactDOM from "react-dom/client";
import { HashRouter, Routes, Route } from "react-router-dom";
import './style.css';
import './bootstrap.min.css';
import Home from "./pages/home";
import Home2 from "./pages/home2";
import Home3 from "./pages/home3";
import Upload from "./pages/upload";
import Monitor1 from "./pages/monitor1/monitor1";
import Monitor2 from "./pages/monitor1/monitor1";
import Homenagem from "./pages/homenagem/Homenagem";
import axios from "axios";
import * as serviceWorker from './serviceWorker';

if (process.env.NODE_ENV !== 'development') {
  axios.defaults.baseURL = window.BaseUrl.PROD;
} else {
  axios.defaults.baseURL = window.BaseUrl.DEV;
}

axios.defaults.headers.common['hash'] = "2bb77fd6284a6818d38b690b8de25c22";

export default function App() {
  return (
    <HashRouter>
      <Routes>
        <Route path="/">
          <Route index element={<Home />} />
        </Route>
        <Route path="/home2" exact>
          <Route index element={<Home2 />} />
        </Route>
        <Route path="/home3" exact>
          <Route index element={<Home3 />} />
        </Route>
        <Route path="/upload" exact>
          <Route index element={<Upload />} />
        </Route>
        <Route path="/monitor1" exact>
          <Route index element={<Monitor1 />} />
        </Route>
        <Route path="/monitor2" exact>
          <Route index element={<Monitor2 />} />
        </Route>
        <Route path="/homenagem" exact>
          <Route index element={<Homenagem />} />
        </Route>
      </Routes>
    </HashRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);

// Registra o service worker
serviceWorker.register();