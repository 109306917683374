import React, { useState } from 'react';
import PhotoService from '../../services/photoServices';
import md5 from 'md5';

const UploadPhoto = () => {
    const [file, setFile] = useState(null);
    const [previewURL, setPreviewURL] = useState('');
    const [error, setError] = useState('');
    const [modalShow, setModalShow] = useState(false);
    const [password, setPassword] = useState('');

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        const allowedTypes = ['image/jpeg', 'image/png'];

        if (selectedFile && allowedTypes.includes(selectedFile.type)) {
            setFile(selectedFile);
            setError('');
            // Pré-visualização da imagem
            const reader = new FileReader();
            reader.onload = () => {
                setPreviewURL(reader.result);
            };
            reader.readAsDataURL(selectedFile);
        } else {
            setFile(null);
            setError('Selecione um arquivo de imagem válido (JPEG, PNG)');
            setPreviewURL('');
        }
    };

    const handleSubmit = async () => {
        if (md5(password) === "26442effb42e24d42f179f343c89e419") {
            if (!file) {
                alert('Nenhum arquivo selecionado');
                return;
            }

            const formData = new FormData();
            formData.append('file', file);

            try {
                let resp = await PhotoService.Upload(formData);
                alert('Imagem salva com sucesso');
                // Limpar estado após envio bem-sucedido
                // setFile(null);
                // setPreviewURL('');
            } catch (error) {
                alert('Erro ao enviar foto');
            }
        } else {
            alert('Senha incorreta');
        }
    };

    const deleteAll = async () => {
        try {
            if (md5(password) === "26442effb42e24d42f179f343c89e419") {
                await PhotoService.DeleteAll();
                alert('Imagens deletadas com sucesso');
                setPassword('');
            } else {
                alert('Senha incorreta');
            }
        } catch (error) { }
    }

    return (
        <div className="flexCenter containerPrincipal">
            <div>
                <div className="containerPreview">
                    {previewURL && <img src={previewURL} alt="Preview" />}
                </div>

                <h2>Upload de Foto</h2>
                <div>
                    <input className="form-control senha" placeholder="Digite aqui a senha para enviar" style={{ width: "100%" }} value={password} type='password' onChange={e => setPassword(e.target.value)} autoFocus></input>
                </div>
                <input className="btn btn-primary" type="file" onChange={handleFileChange} />

                <div>
                    {error && <p style={{ color: 'red' }}>{error}</p>}
                    <button className="btn btn-success" onClick={handleSubmit} disabled={!file}>Enviar Foto</button>
                </div>

                <div>
                    <br />
                    <button className="btn btn-danger" onClick={() => setModalShow(true)}>Apagar todas as imagens</button>
                </div>
            </div>

            <div className={`modal ${modalShow ? 'show' : ''}`}>
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Confirmar exclusão?</h5>
                        </div>

                        <div className="modal-body">
                            <p>Você irá dropar a tabela no banco e apagar todas as imagens salvas no servidor. Tem certeza?</p>
                            <input className="form-control" placeholder="Digite a senha para deletar" style={{ width: "100%" }} value={password} type='password' onChange={e => setPassword(e.target.value)}></input>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={() => setModalShow(false)}>Cancelar</button>
                            <button type="button" className="btn btn-primary" onClick={() => {
                                setModalShow(false);
                                deleteAll();
                            }}>Confirmar</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UploadPhoto;
