import axios from 'axios';

const PhotoService = {
    ObterPhotos() {
        return axios
            .get(`https://api.unsplash.com/photos/random?count=10&client_id=yEpNagMRryPIUh7kRJv-OdTnoaBv4s1k_6C6FK6SeEE&per_page=30&orientation=portrait`)
            .then((response) => {
                return Promise.resolve(response.data);
            })
            .catch((error) => {
                return Promise.reject(error);
            });
    },
    Upload(request) {
        return axios
            .post(`/Photo/Upload`, request)
            .then((response) => {
                return Promise.resolve(response.data);
            })
            .catch((error) => {
                return Promise.reject(error);
            });
    },
    GetPhotos() {
        return axios
            .post(`/Photo/GetPhotos`)
            .then((response) => {
                return Promise.resolve(response.data);
            })
            .catch((error) => {
                return Promise.reject(error);
            });
    },
    DeleteAll() {
        return axios
            .post(`/Photo/DeleteAll`)
            .then((response) => {
                return Promise.resolve(response.data);
            })
            .catch((error) => {
                return Promise.reject(error);
            });
    }
};

export default PhotoService;