import React, { useState, useRef, useEffect } from "react";
import ReactPlayer from "react-player";
const seedrandom = require("seedrandom");

function VideoGlobal(props) {
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
  const playerRef = useRef(null);
  const [links, setLinks] = useState([]);

  const handleVideoEnd = async () => {
    let index = (currentVideoIndex + 1) % props.urls.length;
    setCurrentVideoIndex(index);
    const cache = await caches.open("video-cache-v1");
    cache.add(
      "https://confraternizacao2024.gsoftware.com.br/" +
        props.path +
        links[currentVideoIndex]
    );
  };

  useEffect(() => {
    function shuffleArrayWithSeed(array, seed) {
      // Criar um gerador de números aleatórios com a semente fornecida
      const rng = seedrandom(seed);

      // Algoritmo de Fisher-Yates para embaralhar o array
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(rng() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }

      return array;
    }

    setLinks(shuffleArrayWithSeed(props.urls, Math.random()?.toString()));
  }, [props.urls]);

  let style = {};
  if (props?.style) {
    style = {
      position: "absolute",
      top: 0,
      left: 0,
      overflow: "hidden",
      backgroundImage: "url('img/fundo1.svg')",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center center",
      backgroundSize: "cover",
    };
  } else {
    style = {
      position: "absolute",
      top: 0,
      left: 0,
      overflow: "hidden",
      backgroundColor: "#000",
    };
  }

  return (
    <ReactPlayer
      ref={playerRef}
      url={props.path + links[currentVideoIndex]}
      playing
      width="100%"
      height="100%"
      style={style}
      onEnded={handleVideoEnd}
      muted={true}
      playbackRate={0.75}
    />
  );
}

export default VideoGlobal;
