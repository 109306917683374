import React, { useEffect, useState, useMemo } from 'react';
import PhotoService from '../../services/photoServices';
const seedrandom = require('seedrandom');

function Home2(props) {
  const [photos, setPhotos] = useState([]);
  const totalSpaces = 252;
  const [currentPhoto, setCurrentPhoto] = useState("img/fundo1.svg");
  const [classPhoto, setClassPhoto] = useState("fundo1");
  const [opacity, setOpacity] = useState(window.BaseUrl.OPACITY);


  // useEffect(() => {
  //   var interval = setInterval(() => {
  //     setOpacity(0);
  //     setTimeout(() => {
  //       setOpacity(window.BaseUrl.OPACITY);
  //     }, 5000); // Tempo de espera de 1 segundo antes de restaurar a opacidade
  //   }, 30000); // Executa a cada 2 segundos (tempo total da transição é 2s)
  //   return () => clearInterval(interval); // Limpa o intervalo quando o componente é desmontado
  // }, []);

  useEffect(() => {
    const getPhotos = async () => {
      try {
        let resp2 = await PhotoService.GetPhotos();
        let data = [{ preenchido: false }];
        let photoList = resp2.LstClasse;
        let photoList2 = resp2.LstClasse;
        // console.log(photoList);

        if (resp2.Success) {
          while (photoList.length < totalSpaces) {
            photoList = [...photoList, ...photoList2];
          }

          photoList = shuffleArrayWithSeed([...photoList], "2f31dca2aefa3a05b96ddbd8aea7eca6");

          setPhotos([
            ...photoList,
            ...photoList,
          ]);
        }
      } catch (ex) {

      }
    }

    getPhotos();
    setInterval(() => {
      getPhotos();
    }, 5000);
  }, []);

  function shuffleArrayWithSeed(array, seed) {
    // Criar um gerador de números aleatórios com a semente fornecida
    const rng = seedrandom(seed);

    // Algoritmo de Fisher-Yates para embaralhar o array
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(rng() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }

    return array;
  }

  const getListPhotos2 = useMemo(() => {
    let url;
    if (process.env.NODE_ENV !== 'development') {
      url = window.BaseUrl.PROD_ENDPOINT_PHOTO;
    } else {
      url = window.BaseUrl.DEV_ENDPOINT_PHOTO;
    }

    // console.log(photos);

    return (
      photos.map((photo, index) => {
        if (photo?.preenchido === false) {
          return (
            <div key={index} className="photo-item">
              <div className={`fundoBranco`}></div>
            </div>
          )
        } else {
          return (
            <div key={index} className={`photo-item visivel`}>
              <div className={`fundoBranco`}></div>
              <img src={`${url}/${photo.NomeFoto}`} />
            </div>
          )
        }
      })
    )
  }, [photos]);

  const getStyle = useMemo(() => {
    return photos.length > 0 ? { opacity: opacity, transition: 'opacity 2s ease' } : { opacity: window.BaseUrl.OPACITY };
  }, [photos]);

  return (
    <div className={`containerGlobal ${classPhoto}`}>
      <div className="photo-grid-1">
        {getListPhotos2}

        <div className="logoGlobal" style={getStyle}>
          <img
            src={currentPhoto}
          ></img>
        </div>
      </div>
    </div>
  );
}

export default Home2;